import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const linkStyle = {
  fontWeight: "bold",
  textTransform: "uppercase",
  color: "black",
};

export const navbarHeight = 95;
/**
 * Hides or shows the navbar depending on the scroll value
 */
function scrollFunction() {
  let scrollPos = window.pageYOffset;
  if (navbarHeight > scrollPos) {
    document.getElementById("navbar").style.top = "0";

  } else {
    document.getElementById("navbar").style.top = `-${navbarHeight}px`;
    if (
      document
        .getElementById("responsive-navbar-nav")
        .className.search("show") > 0
    ) {
      // Mobile view hiding toggle
      document.getElementById("toggler").click();
    }
    else if (document.getElementById("collasible-nav-dropdown").getAttribute("aria-expanded") === "true") {
      // Desktop view hiding dropdown
      document.getElementById("collasible-nav-dropdown").click();
    }
  }
}

class Header extends Component {
  componentDidMount() {
    if (typeof window !== `undefined`) {
      // Callback on user's scroll
      window.onscroll = () => scrollFunction();
    }
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
        {
          contentfulAsset(title: {eq: "logo"}) {
            fixed(height: 60, width: 60, quality: 100) {
              ...GatsbyContentfulFixed
            }
            id
            title
          }
          allContentfulSection(sort: { order: ASC, fields: position }) {
            edges {
              node {
                route
                name
                image {
                  title
                  id
                  fluid(sizes: "100") {
                    ...GatsbyContentfulFluid
                  }
                }
                id
              }
            }
          }
        }
        `}
        render={(data) => (
          <Navbar
            id="navbar"
            sticky="top"
            collapseOnSelect
            expand="lg"
            variant="light"
            className="border-bottom"
            style={{
              ...this.props.style,
              transition: "top 0.4s",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              top: `0px`,
            }}
          >
            <Navbar.Brand>
              <Link to="/" style={{ color: "black" }}>
                <Img className="rounded-circle"
                  fixed={data.contentfulAsset.fixed}
                  key={data.contentfulAsset.id}
                  alt={data.contentfulAsset.description || data.contentfulAsset.title}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              id="toggler"
              aria-controls="responsive-navbar-nav"
              style={{ borderColor: "none" }}
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav className="mr-lg-0">
                <NavDropdown
                  title={<span style={linkStyle}>Works</span>}
                  id="collasible-nav-dropdown"
                >
                  {data.allContentfulSection.edges.map((item) => (
                    <NavDropdown.Item key={item.node.id} as="span">
                      <Link to={item.node.route} style={linkStyle}>
                        {item.node.name}
                      </Link>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <Nav.Item>
                  <Nav.Link as="span">
                    <Link to="/about" style={linkStyle}>
                      ABOUT
                    </Link>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as="span">
                    <Link to="/contacts" style={linkStyle}>
                      CONTACT
                    </Link>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      ></StaticQuery>
    );
  }
}

export default Header;
