import React, { Component } from "react";
import { AnimationWrapper } from "react-hover-animation";
import { StaticQuery, graphql } from "gatsby";

const iconStyle = { color: "#959595" };

class Footer extends Component {
  render() {
    return (
      <StaticQuery query={graphql`
      {
        contentfulPerson {
          id
          footer
        }
      }
      `} render={(data) => (<footer className="mt-3 mb-3">
          <div className="text-center p-3" style={iconStyle}>
            {data.contentfulPerson.footer}<br />
      © 2021 mg98photographer<br />
            <a
              className="ml-2"
              href="https://www.tiktok.com/@mg98photographer?lang=it-IT"
              aria-label="MG on TikTok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AnimationWrapper
                style={{ display: "inline" }}
                config={{
                  color: {
                    initial: iconStyle.color,
                    onHover: "black",
                  },
                }}
              >
                <i className="fab fa-tiktok"></i>
              </AnimationWrapper>
            </a>
            <a
              className="ml-2"
              href="https://www.instagram.com/mg98photographer/"
              aria-label="MG on Instagram"
              target="_blank"
              rel="noopener noreferrer"
              style={iconStyle}
            >
              <AnimationWrapper
                style={{ display: "inline" }}
                config={{ color: { initial: iconStyle.color, onHover: "#d6249f" } }}
              >
                <i className="fab fa-instagram"></i>
              </AnimationWrapper>
            </a>
            <a
              className="ml-2"
              href="https://www.youtube.com/channel/UCPiBWedP3g0ayuFbpIZpDMw"
              aria-label="MG on Youtube"
              target="_blank"
              rel="noopener noreferrer"
              style={iconStyle}
            >
              <AnimationWrapper
                style={{ display: "inline" }}
                config={{
                  color: {
                    initial: iconStyle.color,
                    onHover: "red",
                  },
                }}
              >
                <i className="fab fa-youtube"></i>
              </AnimationWrapper>
            </a>
          </div>
        </footer>)} />

    );
  }
}

export default Footer;
